<template>
  <div class="content">
    <section class="listing-property">
      <div class="mobile-search">
        <div class="container-fluid plr-80">
          <b-form-input
            v-model="search"
            placeholder="Berlin"
            class="search-icon"
          ></b-form-input>
        </div>
        <div class="container-fluid plr-80">
          <div class="cal-outer">
            <p>
              <img src="../assets/images/calendar-new.png" />
              <span>1 Oct - 5 Oct 2021</span>
            </p>
            <p><img src="../assets/images/users.png" /><span>2 Adults</span></p>
          </div>
        </div>
        <div class="mobfilter-wrapper">
          <div class="container-fluid plr-80">
            <b-row>
              <b-col>
                <b-form-select
                  v-model="filter_selected"
                  :options="filter_options"
                ></b-form-select
              ></b-col>
              <b-col>
                <b-form-select
                  v-model="sort_selected"
                  :options="sort_options"
                ></b-form-select
              ></b-col>
              <b-col>
                <b-form-select
                  v-model="map_selected"
                  :options="map_options"
                ></b-form-select
              ></b-col>
            </b-row>
          </div>
        </div>
      </div>

      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-md-4 col-lg-3 listing-left-sec">
            <div class="search-wrapper custom-calender">
              <h2>Search</h2>
              <div class="form-outer">
                <b-row class="mb-20">
                  <b-col md="12">
                    <label for="input-small">Destination/property name</label>
                  </b-col>
                  <b-col md="12">
                    <b-form-input
                      id="input-small"
                      placeholder="Berlin"
                      class="input"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-20">
                  <b-col md="12">
                    <label for="input-small">Check-in date</label
                    ><HotelDatePicker
                      format="dddd DD MMMM YYYY"
                      ref="datepicker"
                      :i18n="i18n"
                    >
                    </HotelDatePicker
                  ></b-col>
                </b-row>
                <b-row class="mb-20">
                  <b-col md="12">
                    <b-form-select
                      v-model="selected_adults"
                      :options="adult_options"
                      class="input"
                    >
                    </b-form-select>
                  </b-col>
                </b-row>

                <b-row class="mb-20">
                  <b-col md="6">
                    <b-form-select
                      v-model="selected_childrens"
                      :options="children_options"
                      class="input"
                    >
                    </b-form-select>
                  </b-col>

                  <b-col md="6">
                    <b-form-select
                      v-model="selected_rooms"
                      :options="rooms_options"
                      class="input"
                    >
                    </b-form-select>
                  </b-col>
                </b-row>
                <b-row class="submit-wrapper">
                  <b-col md="12"><b-button class="">Search</b-button></b-col>
                </b-row>
              </div>
            </div>

            <div class="filter-wrapper">
              <div class="budget col-12">
                <h4>Your budget (per night)</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>Type of place</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Entire place</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hotel</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hostel</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Private room</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>Popular filter</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg"
                      >Breakfast included</b-form-checkbox
                    >
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hotels</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Very good: 8+</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Less that 3 km</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Double bed</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>Your budget (per night)</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>Your budget (per night)</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-9 listing-right-sec">
            <div class="row align-items-center top-bar">
              <div class="col-6">
                <h2>Berlin: 116 accommodations found</h2>
              </div>
              <div class="col-6 text-right">
                <p class="position-relative float-right">
                  <img src="../assets/images/map.png" alt="" />
                  <a href="#" class="show-on-map">Show on map</a>
                </p>
              </div>

              <div class="col-12">
                <div class="tab-sorting">
                  <a href="#" class="active">Our recomendations</a>
                  <a href="#">Hotels </a>
                  <a href="#">Entire homes & apartments</a>
                  <a href="#"
                    >Price<i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                  <a href="#" class="border-none"
                    >Reviews<i class="fa fa-caret-down" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
            </div>

            <div class="row hotel-block">
              <div class="col-md-4 col-lg-5 col-6">
                <div class="hotel-slider">
                  <b-carousel
                    id="carousel-fade"
                    :interval="3000"
                    style="text-shadow: 0px 0px 2px #000"
                    fade
                    indicators
                    img-width="1024"
                    img-height="480"
                    ><router-link to="/hotel_booking">
                      <b-carousel-slide
                        img-src="../assets/images/Frame 60.png"
                      ></b-carousel-slide>
                      <b-carousel-slide
                        img-src="../assets/images/Frame 27.png"
                      ></b-carousel-slide>
                    </router-link>
                  </b-carousel>
                </div>
              </div>
              <div class="col-md-8 col-lg-7 col-6 hotelTxtInner">
                <div class="hotel-txt">
                  <div class="hotel-rating row">
                    <div class="col-md-8">
                      <router-link to="/hotel_booking"
                        ><img
                          src="../assets/images/chevron-right-large.png"
                          alt=""
                          class="posIcon"
                      /></router-link>
                      <p>Hotel booking</p>
                      <h4 class="d-flex align-items-center">
                        <span
                          ><router-link to="/hotel_booking" style="color: #444">
                            Hotel Berlin</router-link
                          ></span
                        ><span class="starWrapper"
                          ><i class="fa fa-star" aria-hidden="true"></i
                          ><i class="fa fa-star" aria-hidden="true"></i
                          ><i class="fa fa-star" aria-hidden="true"></i
                          ><i class="fa fa-star" aria-hidden="true"></i
                          ><i class="fa fa-star" aria-hidden="true"></i
                        ></span>
                      </h4>
                      <div class="access d-flex">
                        <li><a href="#">Mitte, Berlin</a></li>
                        <li><a href="#">Show on map</a></li>
                        <li>2.6 km from centre</li>
                        <li>Metro access</li>
                      </div>
                    </div>
                    <div class="col-md-4 d-flex review">
                      <h5 class="text-center">
                        Very good
                        <span class="d-block text-center">2,176 reviews</span>
                      </h5>
                      <span class="rating">8.6</span>
                    </div>
                  </div>

                  <div class="hotel-room row">
                    <div class="col-md-8">
                      <h6>
                        Deluxe King room
                        <span>1 extra-large double bed</span>
                      </h6>
                    </div>
                    <div class="col-md-4 justify-content-end">
                      <p>29 nights, 2 adults</p>
                      <h4>€ 1,000</h4>
                    </div>
                  </div>

                  <div class="hotel-request d-flex">
                    <a href="#">Free cancellation</a>
                    <a href="#">Need request</a>
                  </div>

                  <div
                    class="
                      availability
                      justify-content-between
                      d-flex
                      align-items-center
                    "
                  >
                    <a href="#">Only 5 rooms left at this hotel on our site</a>
                    <b-button v-on:click="redirect()"
                      >See availability</b-button
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row hotel-block no-availability">
              <div class="col-md-4 col-lg-5 col-6">
                <div class="hotel-slider">
                  <b-carousel
                    id="carousel-fade"
                    :interval="3000"
                    style="text-shadow: 0px 0px 2px #000"
                    fade
                    indicators
                    img-width="1024"
                    img-height="480"
                  >
                    <b-carousel-slide
                      img-src="../assets/images/Frame 60.png"
                    ></b-carousel-slide>
                    <b-carousel-slide
                      img-src="../assets/images/Frame 27.png"
                    ></b-carousel-slide>
                  </b-carousel>
                </div>
              </div>
              <div class="col-md-8 col-lg-7 col-6 hotelTxtInner">
                <div class="hotel-txt">
                  <div class="hotel-rating row">
                    <div class="col-md-8">
                      <p>Hotel booking</p>
                      <h4 class="d-flex align-items-center">
                        <span> Hotel Berlin</span
                        ><span class="starWrapper"
                          ><i class="fa fa-star" aria-hidden="true"></i
                          ><i class="fa fa-star" aria-hidden="true"></i
                          ><i class="fa fa-star" aria-hidden="true"></i
                          ><i class="fa fa-star" aria-hidden="true"></i
                          ><i class="fa fa-star" aria-hidden="true"></i
                        ></span>
                      </h4>
                      <div class="access d-flex">
                        <li><a href="#">Mitte, Berlin</a></li>
                        <li><a href="#">Show on map</a></li>
                        <li>2.6 km from centre</li>
                        <li>Metro access</li>
                      </div>
                    </div>
                    <div
                      class="
                        col-md-4
                        d-flex
                        align-items-center
                        justify-content-end
                        review
                      "
                    >
                      <h5 class="text-center">
                        Very good
                        <span class="d-block text-center">2,176 reviews</span>
                      </h5>
                      <span class="rating">8.6</span>
                    </div>
                  </div>

                  <a href="#" class="no-avail"
                    >This poperty has no availability on our site from</a
                  >
                  <div class="row">
                    <div class="col-md-6 col-lg-3">
                      <div class="dates">
                        <h6>1 Nov - 30 Nov</h6>
                        <span>29 nights</span>
                        <p class="price">From € 2,500</p>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <div class="dates">
                        <h6>1 Nov - 30 Nov</h6>
                        <span>29 nights</span>
                        <p class="price">From € 2,500</p>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <div class="dates">
                        <h6>1 Nov - 30 Nov</h6>
                        <span>29 nights</span>
                        <p class="price">From € 2,500</p>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <div class="dates">
                        <h6>1 Nov - 30 Nov</h6>
                        <span>29 nights</span>
                        <p class="price">From € 2,500</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row pagination-outer align-items-center">
              <div class="col-6 pagi-nation">
                <b-pagination-nav
                  number-of-pages="10"
                  v-model="itemsPagination.current_page"
                  base-url="#"
                ></b-pagination-nav>
              </div>
              <div class="col-6 text-right sort">Showing 1 - 25</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HotelDatePicker from "vue-hotel-datepicker";

import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";
export default {
  name: "PropertyListing",
  components: {
    HotelDatePicker,
  },

  data() {
    return {
      i18n: {
        selected: "Your stay:",
        night: "Night",
        nights: "Nights",
        button: "Close",
        "check-in": "Check-in",
        "check-out": "Check-Out",

        "day-names": [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        "month-names": [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        "error-more": "Date range should not be more than 1 night",
        "error-more-plural": "Date range should not be more than %d nights",
        "error-less": "Date range should not be less than 1 night",
        "error-less-plural": "Date range should not be less than %d nights",
        "info-more": "Please select a date range longer than 1 night",
        "info-more-plural": "Please select a date range longer than %d nights",
        "info-range": "Please select a date range between %d and %d nights",
        "info-default": "Please select a date range",
        tooltip: {
          halfDayCheckIn: "Available CheckIn",
          halfDayCheckOut: "Available CheckOut",
          saturdayToSaturday: "Only Saturday to Saturday",
          sundayToSunday: "Only Sunday to Sunday",
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum.",
        },
      },

      itemsPagination: {
        current_page: 1,
      },
      selected_adults: "null",
      adult_options: [
        {
          text: "1 Adult",
          value: null,
        },
        {
          text: "2 Adults",
          value: 2,
        },
        {
          text: "3 Adults",
          value: 3,
        },
        {
          text: "4 Adults",
          value: 4,
        },
      ],
      selected_childrens: "null",
      children_options: [
        {
          text: "Children",
          value: null,
        },
        {
          text: "1 Child",
          value: 1,
        },
      ],
      selected_rooms: "null",
      rooms_options: [
        {
          text: "1 Room",
          value: null,
        },
        {
          text: "2 Rooms",
          value: 2,
        },
        {
          text: "3 Rooms",
          value: 3,
        },
        {
          text: "4 Rooms",
          value: 4,
        },
      ],
      filter_selected: "null",
      filter_options: [
        {
          text: "Filter",
        },
        {
          text: "Hotels",
          value: 1,
        },
        {
          text: "Entire homes & apartments",
          value: 1,
        },
      ],
      sort_selected: "null",
      sort_options: [
        {
          text: "Sort",
        },
        {
          text: "Price",
          value: 1,
        },
        {
          text: "Reviews",
          value: 2,
        },
      ],
      map_selected: "null",
      map_options: [
        {
          text: "Map",
        },
      ],
      selected: "",
      options: [],
      search: "",
    };
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.datepicker.open;
      },
      (val) => {
        if (val) {
          var week_name = document.getElementsByClassName(
            "vhd__datepicker__week-name"
          );
          for (var i = 0; i < week_name.length; i++) {
            week_name[i].innerHTML = week_name[i].innerHTML.substr(0, 4);
          }
        }
      },
      { deep: true }
    );
    this.$watch(
      () => {
        return this.$refs.datepicker.checkIn;
      },
      (val) => {
        if (val) {
          var week_name = document.getElementsByClassName(
            "vhd__datepicker__week-name"
          );
          for (var i = 0; i < week_name.length; i++) {
            week_name[i].innerHTML = week_name[i].innerHTML.substr(0, 4);
          }
        }
      },
      { deep: true }
    );
  },
  methods: {
    redirect() {
      this.$router.push("/hotel_booking");
    },
    dateFunction() {
      var week_name = document.getElementsByClassName(
        "vhd__datepicker__week-name"
      );
      for (var i = 0; i < week_name.length; i++) {
        week_name[i].innerHTML = week_name[i].innerHTML.substr(0, 4);
      }
    },
  },
};
</script>
